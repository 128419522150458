import 'react-app-polyfill/ie11';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import PurchasesControl from "./Views/PurchasesControl/PurchasesControl";
import reportWebVitals from './reportWebVitals';
import {
    BrowserRouter,
    createBrowserRouter, Route,
    RouterProvider, Routes,
} from "react-router-dom";

// const router = createBrowserRouter([
//     {
//         path: "/",
//         element: <App/>
//     },
//     {
//         path: "/purchases",
//         element: <PurchasesControl/>
//     }
// ], { basename: "/" });
//<RouterProvider router={router} />

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
      <BrowserRouter>
          <Routes>
              <Route path="/" element={<App/>}/>
              <Route path = "/purchases" element={<PurchasesControl/>} />
          </Routes>
      </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
