import React, {useState, useEffect} from 'react';
import Purchase from "../../Models/Purchase";
import axios from "axios";
import {
    Paper,
    Table,
    TableCell,
    TableRow,
    TableHead,
    TableBody,
    TableContainer,
    TextField,
    Button,
    List, ListSubheader, ListItem, ListItemIcon, Switch, ListItemText, CircularProgress, Modal, Box, Typography
} from "@mui/material";
import KeyIcon from '@mui/icons-material/Key';
import PurchasesControlModal from "./PurchasesControlModal/PurchasesControlModal";

function PurchasesControl() {
    const [purchases, setPurchases] = useState<Array<Purchase>>([]);
    const [serverError, setServerError] = useState(false);
    const [keysStatus, setKeysStatus] = useState(-1);
    const [keysStatusLastErrors, setKeysStatusLastErrors] = useState([]);

    //Modal
    const [open, setOpen] = useState(false);
    const handleOpen = (purchase: Purchase) => {
        setModalPurchase(purchase);
        setOpen(true)
    }
    const handleClose = () => setOpen(false);
    const [modalPurchase, setModalPurchase] = useState<Purchase>();

    //Errors modal
    const [openErrors, setOpenErrors] = useState(false);
    const handleOpenErrors = () => setOpenErrors(true);
    const handleCloseErrors = () => setOpenErrors(false);
    const errorsModalStyle = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    async function GetAllPurchases(): Promise<[number, Array<Purchase>]> {
        const purchasesResponse = await axios.get("https://batyaxboxa.ru:444/api/getAllPurchases");
        return [purchasesResponse.status, purchasesResponse.data.map((p: any) => new Purchase(p.uniqueCode, p.key, p._id, p.name))]
    }

    async function GetKeysState(): Promise<any> {
        const keysStateResponse = await axios.get("https://batyaxboxa.ru:444/api/getKeysState");
        return keysStateResponse.data;
    }

    function OnInputChanged(newKey: string, purchaseId: string) {
        const nextPurchases : Array<Purchase> = purchases.map(p => {
            if (p.id !== purchaseId) return p;
            p.key = newKey;
            return p;
        });
        setPurchases(nextPurchases);
    }

    function OnKeysToggleChanged() {
        if (keysStatus != 0 && keysStatus != 1 && keysStatus != 3 && keysStatus != 4) return;
        let enable : Boolean = keysStatus == 3 || keysStatus == 4;
        setKeysStatus(enable ? 2 : 5);
        axios.get("https://batyaxboxa.ru:444/api/toggleKeys?toggleValue=" + (enable ? "enabled" : "disabled"));
    }

    useEffect(() => {
        GetAllPurchases()
            .then(result => {
                setPurchases(result[1])

                let interval: NodeJS.Timer;
                const fetcher: Function = () => {
                    GetKeysState()
                        .then(result => {
                            setKeysStatus(result.keysStatus);
                            setKeysStatusLastErrors(result.keysStatusLastErrors);
                        })
                }
                fetcher();
                interval = setInterval(() => {
                    fetcher()
                }, 5000);
            })
            .catch(err => {
                setServerError(true);
            })
    }, [open])

    return (
        <TableContainer component={Paper}>
            <Table sx={{maxWidth: 1000}} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Уникальный код</TableCell>
                        <TableCell>Название</TableCell>
                        <TableCell align="right">Ключ</TableCell>
                        <TableCell align="right"></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {purchases.map((purchase) => (
                        <TableRow
                            key={purchase.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">{purchase.uniqueCode}</TableCell>
                            <TableCell component="th" scope="row">{purchase.name}</TableCell>
                            <TableCell align="right">
                                <TextField id="outlined-basic" label="Ключ" variant="outlined" onChange={e => {OnInputChanged(e.target.value, purchase.id)}} />
                            </TableCell>
                            <TableCell align="right">
                                <Button variant="contained" onClick={e => {handleOpen(purchase)}}>Отправить</Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <PurchasesControlModal open={open} handleClose={handleClose} purchase={modalPurchase}/>

            <List
                sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                subheader={<ListSubheader>Настройки</ListSubheader>}
            >
                {keysStatus != - 1 && <ListItem>
                    <ListItemIcon>
                        <KeyIcon></KeyIcon>
                    </ListItemIcon>
                    <ListItemText id="switch-list-label-keys" primary="Ключи" />
                    <Switch
                        edge="end"
                        onChange={OnKeysToggleChanged}
                        checked={keysStatus == 0 || keysStatus == 1 || keysStatus == 2}
                        disabled={keysStatus == 2 || keysStatus == 5}
                        inputProps={{
                            'aria-labelledby': 'switch-list-label-wifi',
                        }}
                    />
                    {(keysStatus == 2 || keysStatus == 5) && <CircularProgress/>}
                    {keysStatus == 2 && <div>Включаем ключи</div>}
                    {keysStatus == 5 && <div>Выключаем ключи</div>}
                    {(keysStatus == 1 || keysStatus == 4) && <Button onClick={handleOpenErrors}>Ошибки</Button>}
                    <Modal
                        open={openErrors}
                        onClose={handleCloseErrors}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={errorsModalStyle}>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                Ошибки
                            </Typography>
                            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                {keysStatusLastErrors.map((e: any) => (
                                    <li key={e.Key}>
                                        {e.Key}
                                        <br/>
                                        {e.Value}
                                    </li>
                                ))}
                            </Typography>
                        </Box>
                    </Modal>
                </ListItem>}
            </List>
        </TableContainer>
    );
}


export default PurchasesControl