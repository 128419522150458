import {Backdrop, Box, Button, Fade, Modal, Typography} from "@mui/material";
import React from "react";
import Purchase from "../../../Models/Purchase";
import axios from "axios";

function PurchasesControlModal(props: any) {
    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    async function SetPurchaseKey(purchase: Purchase) {
        console.log("here");
        await axios.post("https://batyaxboxa.ru:444/api/setPurchase", {
            "uniqueCode": purchase.uniqueCode,
            "key": purchase.key
        });
    }

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={props.open}
            onClose={props.handleClose}
            closeAfterTransition
            slots={{backdrop: Backdrop}}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={props.open}>
                <Box sx={style}>
                    <Typography id="transition-modal-title" variant="h6" component="h2">
                        Подтвердите отправку ключа
                    </Typography>
                    <Typography id="transition-modal-description" sx={{mt: 2}} variant='h5'>
                        Уникальный код: {props.purchase?.uniqueCode}
                    </Typography>
                    <Typography id="transition-modal-description" sx={{mt: 2}}>
                        Ключ: {props.purchase?.key}
                    </Typography>
                    <Button variant="contained" onClick={e => {
                        SetPurchaseKey(props.purchase)
                            .then(() => {
                                props.handleClose();
                            })
                            .catch(() => {

                            })
                    }
                    }>Отправить</Button>
                </Box>
            </Fade>
        </Modal>
    );
}

export default PurchasesControlModal