class Purchase {
    public uniqueCode: string;
    public key: string;
    public id: string;
    public name: string;

    constructor(uniqueCode: string, key: string, id: string, name: string) {
        this.uniqueCode = uniqueCode;
        this.key = key;
        this.id = id;
        this.name = name;
    }

}

export default Purchase;