import React, {useState, useEffect} from 'react';
import './App.css';
import {useSearchParams} from "react-router-dom";
import axios from "axios";
import {Box, CircularProgress, Typography} from "@mui/material";
import LinearProgress, {LinearProgressProps} from '@mui/material/LinearProgress';

async function GetKey(uniqueCode: string): Promise<[number, { result: string, progress: number }]> {
    const response = await axios.get("https://batyaxboxa.ru:444/api/getPurchase?uniqueCode=" + uniqueCode);
    return [response.status, response.data];
}

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
    return (
        <Box sx={{display: 'flex', alignItems: 'center'}}>
            <Box sx={{width: '100%', mr: 1}}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{minWidth: 35}}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

function getMondayDate() {
    let currentDateObj = new Date();
    currentDateObj.setDate(currentDateObj.getDate() - (currentDateObj.getDay() + 1) % 7);
    return currentDateObj;
}

function App() {
    const [key, setKey] = useState("AAAAA-AAAAA-AAAAA-AAAAA-AAAAA");
    const [lastStatus, setLastStatus] = useState(0);
    const [progress, setProgress] = useState(0);

    const [searchParams] = useSearchParams();
    const uniqueCode = searchParams.get("uniquecode");

    useEffect(() => {
        if (uniqueCode !== null) {
            let interval: NodeJS.Timer;
            const fetcher: Function = () => {
                let updatedStatus: number;
                GetKey(uniqueCode)
                    .then(result => {
                        setLastStatus(result[0])
                        updatedStatus = result[0];
                        if (updatedStatus === 201) {
                            setProgress((prevProgress) => {
                               if (result[1].progress > 100) {
                                   return 100;
                               }
                               if (result[1].progress >= prevProgress) {
                                   return result[1].progress;
                               }
                               return prevProgress;
                            });
                        }
                        if (updatedStatus === 200) {
                            setKey(result[1].result);
                        }
                    })
                    .catch(err => {
                        setLastStatus(err.response.status);
                        updatedStatus = err.response.status;
                    })
                    .finally(() => {
                        if (updatedStatus !== 201) {
                            if (interval !== undefined) clearInterval(interval);
                        }
                    })
            }
            fetcher();
            interval = setInterval(() => {
                fetcher()
            }, 5000);
        }
    }, [])

    return (
        <div className="App">
            {uniqueCode === null && <h1>Страница получения ключа магазина Batya Xboxa</h1>}
            {uniqueCode !== null && lastStatus === 0 && <CircularProgress/>}
            {lastStatus > 201 && <h1>Уникальный код невалиден!</h1>}
            {lastStatus > 0 && lastStatus <= 201 && <div>
                <h3>Ваш ключ</h3>
                <h2 className={lastStatus === 201 ? "Blur" : ""}>{key}</h2>
                {lastStatus === 201 && progress < 100 && <div>
                    <h4>Проверяем платеж, уточняем регион ключа</h4>
                    <Box sx={{width: '100%'}}>
                        <LinearProgressWithLabel value={progress}/>
                    </Box>
                    <h5>{progress >= 50 ? "Осталось меньше минуты" : "Осталось примерно 2 минуты"}</h5>
                </div>}
                {lastStatus === 201 && progress >= 100 && <div>
                    <CircularProgress/>
                    <h4>Пожалуйста, подождите еще немного. Если код так и не отобразится, то свяжитесь с <a href="https://t.me/BatyaXboxa">поддержкой</a>.</h4>
                </div>}
                <div className="Instruction">
                    Инструкция по активации ключа: <br/>
                    1. Установите приложение <b><u>seed 4 me</u></b> на телефон или компьютер <br/>
                    2. Запустите его, выберите страну, указанную на странице товара (Почти всегда это Аргентина или
                    Турция) <br/>
                    3. Перейдите по ссылке <a href="https://redeem.microsoft.com">https://redeem.microsoft.com</a><br/>
                    4. Войдите в свою учетную запись <b>xbox</b> <br/>
                    5. Активируйте Ваш ключ <br/>
                </div>
                <br/>
                <div className="Instruction">
                    Данные для входа в <b><u>seed 4 me</u></b><br/>
                    Почта: <b>batyaxb0xa{getMondayDate().getDate()}{getMondayDate().getMonth()+1}{getMondayDate().getFullYear()}@outlook.com</b>
                    <br/>
                    Пароль: <b>qsefzxc123</b> <br/>
                </div>
            </div>}
        </div>
    );
}

export default App;
